import { ChatItem } from "@nlux/react";

type Message = {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

export function buildMessages(systemPrompt: string, history: ChatItem<string>[], prompt: string): Message[] {
  return [
    {
      role: 'system',
      content: systemPrompt
    },
    ...history.map((message: ChatItem<string>) => {
      return {
        role: message.role,
        content: message.message
      }
    }),
    {
      role: "user",
      content: prompt
    }
  ]
}
