import { useCallback, useEffect, useState } from 'react';
import { toast } from "sonner";

export default function useNavigatorLocation() {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  function parseCoordinates(position: GeolocationPosition) {
    const lat = position.coords?.latitude;
    const lng = position.coords?.longitude;
    setLatitude(lat);
    setLongitude(lng);

    // If there’s a way to add a quick tool so I can submit lat/long coords manually,
    // then we could also test manhattan results remotely (albeit imperfectly) using google streetview
    // setLatitude(-90);
    // setLongitude(180);

    // toast.success('Located your GPS coordinates!');
  }

  function parseCoordinatesError(err: GeolocationPositionError) {
    let errorMessage: string = 'You must enable geolocation to use Lubricant Assistant.';
    switch(err.code) {
      case err.PERMISSION_DENIED:
        errorMessage = "User denied the request for Geolocation.";
        break;
      case err.POSITION_UNAVAILABLE:
        errorMessage = "Location information is unavailable.";
        break;
      case err.TIMEOUT:
        errorMessage = "The request to get user location timed out.";
        break;
      default:
        errorMessage = "An unknown error occurred.";
        break;
    }
    setError(errorMessage);
    toast.error(errorMessage);
  }

  const getPosition = useCallback(() => {
    setLoading(true);

    if (!navigator.geolocation) {
      let errorMessage: string = 'Geolocation is not supported by this browser.';
      setError(errorMessage);
      toast.error(errorMessage);
    } else {
      navigator.geolocation.getCurrentPosition(parseCoordinates, parseCoordinatesError);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const tenMinutes = 1000 * 60 * 10;
  
    setInterval(getPosition, tenMinutes);
    getPosition();
  }, [getPosition]);

  return {getPosition, latitude, longitude, error, loading};
};
