export async function textToSpeech(message: string): Promise<void> {
  try {
    // Use the Web Speech API for text-to-speech
    const utterance = new SpeechSynthesisUtterance(message);
    const voices = speechSynthesis.getVoices();
    utterance.voice = voices.find(voice => voice.name === "Samantha") || null;
    speechSynthesis.speak(utterance);
  } catch (error) {
    console.error("Error generating speech:", error);
  }
};

export function stopSpeech(): void {
  if (speechSynthesis.speaking) {
    speechSynthesis.cancel();
  }
};
