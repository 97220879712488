import { ChatAdapterExtras } from '@nlux/react';

import { system as systemPrompt } from '../../prompts/system';
import { buildMessages } from '../../lib/adapters';

// A demo API by NLUX that connects to OpenAI
// and returns a stream of Server-Sent events

const endpointUrl = 'https://models.inference.ai.azure.com/chat/completions';
const apiKey = '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';

const openAiEndpointUrl = 'https://api.openai.com/v1/chat/completions';
// const openAiApiKey = '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';
const openAiApiKey = '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';

// const xApiKey = 'xai-Th4DBWatJwbHb6iU91ARk74BBHE3p6ZTBIgnzbGKmAa45cpW5gIcJzhN3QX7HAlJe8oeFRUSb9nL8TA3';
// const xModel = 'grok-beta';
// const xEndpointUrl = 'https://api.x.ai/v1/chat/completions';

// Function to send query to the server and receive a stream of chunks as response
export const send = async (
	prompt: string,
	extras: ChatAdapterExtras
): Promise<string> => {
	const model = 'gpt-4o-mini';
	const history = extras.conversationHistory ?? [];

	const body = {
		messages: buildMessages(systemPrompt, history, prompt),
		model,
		temperature: 1,
		max_tokens: 2048,
		top_p: 1,
		// stream: true,
		// stop: null
	};

	const response = await fetch(openAiEndpointUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${openAiApiKey}`
		},
		body: JSON.stringify(body),
	});
  const data = await response.json();

  const chatCompletionMessage = data.choices?.[0]?.message?.content ?? '';
  return chatCompletionMessage;
};