import { PersonaOptions } from '@nlux/react';

// const assistantAvatar = 'https://docs.nlkit.com/nlux/images/personas/hawking.png';
const assistantAvatar = '/logo.png'
const userAvatar = '/setel.png';

export const personas: PersonaOptions = {
	assistant: {
		name: 'SETEL Lubricants AI',
		avatar: assistantAvatar,
		tagline: 'PETRONAS lubricants for your mobility needs!',
	},
	user: {
		name: 'Me',
		avatar: userAvatar,
	}
};
