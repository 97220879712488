import { useCallback, useEffect, useState, useRef } from 'react';
// import { Loader2 } from "lucide-react";
import {
  AiChat,
  useAiChatApi,
  useAsBatchAdapter,
  useAsStreamAdapter,
} from '@nlux/react';
import '@nlux/themes/nova.css';

// import { send as sendStream } from './adapters/stream/default';
import { send } from './adapters/batch/default';

import { personas } from './personas';

import useNavigatorLocation from './hooks/useNavigatorLocation';

import { Button } from "./components/ui/button";
import { Toaster } from "./components/ui/sonner";
import { toast } from "sonner";

import { db } from './lib/db';
import { textToSpeech, stopSpeech } from './lib/tts';
import { uuid } from './lib/uuid';

import styles from './App.styles.module.css';

function App () {
  const { getPosition, latitude, longitude, error, loading: locationLoading } = useNavigatorLocation();

  const [isTtsEnabled, setIsTtsEnabled] = useState(false);
  function toggleTts () {
    setIsTtsEnabled(!isTtsEnabled);
    stopSpeech();
  }
  const recognitionRef = useRef();
  const [isSpeechRecognitionSupported, setIsSpeechRecognitionSupported] = useState(false);
  const recognitionLanguage = 'en-US';
  const [isSpeechRecognitionLoading, setIsSpeechRecognitionLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  // const adapter = useAsStreamAdapter(sendStream);
  const adapter = useAsBatchAdapter(send, []);

  const [messages, setMessages] = useState([]);
  async function appendMessage (content, role) {
    const message = {
      id: uuid(),
      role,
      message: content,
      timestamp: Date.now()
    };
    setMessages((previous) => [...previous, message]);
    await db.chat.add(message);
  }


  function handleOnRecord () {
    if (isRecording) {
      stopRecognition();
      return;
    }

    startRecognition();
  }

  async function startRecognition () {
    try {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();

      recognitionRef.current.lang = recognitionLanguage;
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = false;

      recognitionRef.current.onstart = function (event) {
        setIsRecording(true);
        toast.success('Speech recording started!');
        setIsSpeechRecognitionLoading(false);
      }

      recognitionRef.current.onerror = function (event) {
        setIsRecording(false);
        setIsSpeechRecognitionLoading(false);
      }

      recognitionRef.current.onresult = function (event) {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');

        console.log('transcript:', transcript);
        if (!transcript) return;
        api.composer.send(transcript);
      }

      setIsSpeechRecognitionLoading(true);
      recognitionRef.current.start();
    } catch (error) {
      toast.error('Failed to record speech:', error);
    }
  }

  function stopRecognition (showNotification = false) {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    setIsRecording(false);
    if (showNotification) {
      toast.info('Speech recording ended!');
    }
  }

  const api = useAiChatApi();
  const onResetClick = useCallback(async () => {
    stopSpeech();
    api.conversation.reset();
    setMessages([]);
    await db.chat.clear();
    // toast.success('Cleared chats!');
  }, [api]);

  const onMessageReceived = useCallback(async (payload) => {
    // const message = payload.message.join('');
    const message = payload.message;
    // Call the text-to-speech function
    if (isTtsEnabled) {
      await textToSpeech(message);
    }
    // await appendMessage(message, 'assistant');
  }, [isTtsEnabled]);

  const onMessageSent = useCallback(async (payload) => {
    const message = payload.message;
    // console.log(`message: ${message}`);
    // await appendMessage(message, 'user');
  }, []);

  // const onMessageRendered = useCallback(async (payload) => {
  //   setConversationHistory(messages)
  // }, [messages]);

  const eventCallbacks = {
    messageReceived: onMessageReceived,
    messageSent: onMessageSent,
    // messageRendered: onMessageRendered
  };

  useEffect(() => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      setIsSpeechRecognitionSupported(true);
      // FireFox: in `about:config`- enable `media.webspeech.recognition.enable` + `media.webspeech.recognition.force_enable`
    }

    async function getChat () {
      const chatMessages = await db.chat
        .orderBy('timestamp')
        .filter(chat => chat.role !== 'system')
        .toArray();

      setMessages(chatMessages);
    }

    getChat();

    return () => {
      stopRecognition();
    };
  }, []);

  return (
    <>
      <div className={styles.appContainer}>
        <div className={styles.main}>
          <div>
            <div className='flex items-center justify-center gap-2 text-2xl'>
              <Button
                variant="secondary"
                onClick={toggleTts}
              >
                {
                  isTtsEnabled ? '🔇' : '🔈'
                }
              </Button>
              <h1 className='text-3xl'>
                Lubricants Assistant
              </h1>
            </div>


            <div className='py-2'>
              Hyper-localized AI expert on PETRONAS lubricants
            </div>
          </div>

          {/* {
            locationLoading ? (
              <div className={styles.splitContainer}>
                <Loader2 className={'animate-spin'} />
                <h2>coordinates...</h2>
              </div>
            ) : (
              <div className={`${styles.splitContainer} ${styles.coordinatesContainer}`}>
                <h2>
                  📍 {latitude}, {longitude}
                </h2>
                <Button
                  variant="secondary"
                  onClick={getPosition}
                  disabled={locationLoading}
                >
                  
                  {locationLoading ? (
                    <Loader2 className={'animate-spin'} />
                  ) : (
                    <>
                      🧭 me!
                    </>
                  )}
                </Button>
              </div>
            )
          } */}

          <div className={styles.splitContainer}>
            {
              messages.length > 0 && (
                <Button
                  variant="destructive"
                  onClick={onResetClick}
                >
                  🗑️ chats.
                </Button>
              )
            }
            {/* {
              isSpeechRecognitionSupported &&
              <Button
                variant="secondary"
                onClick={handleOnRecord}
                disabled={isSpeechRecognitionLoading}
              >
                {
                  isSpeechRecognitionLoading ? (
                    <Loader2 className={'animate-spin'} />
                  ) : (
                    isRecording ? '🔇 stop.' : '🎙️ speak.'
                  )
                }
              </Button>
            } */}
          </div>
        </div>

        <AiChat
          messageOptions={{
            editableUserMessages: true
          }}
          api={api}
          adapter={adapter}
          className={styles.aiChat}
          events={eventCallbacks}
          initialConversation={messages?.length && messages}
          personaOptions={personas}
          displayOptions={{ colorScheme: 'dark' }}
        />
      </div>

      <Toaster
        position="top-right"
        expand
        closeButton
        richColors
      />
    </>
  );
}

export default App;
