import Dexie from "dexie";

function initializeDatabase() {
  const database = new Dexie("LubricantAI");
  database.version(1).stores({
    chat: "id, role, message, timestamp",
    // coordinates: "id, latitude, longitude, lastUpdated"
  });
  return database;
}

export const db = initializeDatabase();
